





























































































































































import { Component, Watch, Vue } from "vue-property-decorator";
import omitDeep from "omit-deep-lodash";
import { mixins } from "vue-class-component";
import { Routes } from "@/types/core";
import {
  createEmployeeProfile,
  CreateUser,
  EmployeeById,
  UpdateUser,
} from "@/graphql/queries/User.graphql";
import { BaseView } from "@/core/UI/Views/BaseView";
import {
  CreateUserInput,
  CreateUserMutation,
  CreateUserMutationVariables,
  EmployeeByIdQuery,
  EmployeeByIdQueryVariables,
  JsonInput,
  Maybe,
  UpdateEmployeeProfileInput,
  UpdateUserInput,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  User,
} from "@/generated/graphql";

import UserEvents from "@widgets/user/UserEvents.vue";
import userRolesMixin from "@/mixins/userRoles";
import UserCommonProfile from "@widgets/user/UserCommonProfile.vue";
import UserPassword from "@widgets/user/UserPassword.vue";
import UserDeleteButton from "@widgets/user/UserDeleteButton.vue";
import UserDeactivate from "@widgets/user/UserDeactivate.vue";
import UserCreationDialog from "@/components/widgets/user/UserCreationDialog.vue";

import UserEmployeeProfile from "@/components/widgets/user/UserEmployeeProfile.vue";
import MutationTransformer from "@/core/Transformers/GraphqlMutationFieldsTransformer";
import { cloneDeep, merge } from "lodash";
import { isRequired } from "@/core/typeGuards";
import { rules } from "@/plugins/vee-validate";

@Component({
  components: {
    UserEvents,
    UserCommonProfile,
    UserPassword,
    UserDeleteButton,
    UserDeactivate,
    UserCreationDialog,
    UserEmployeeProfile,
  },
  apollo: {
    user: {
      query: EmployeeById,
      variables(): EmployeeByIdQueryVariables {
        return { id: this.$route.params.id };
      },
      skip() {
        return this.creationMode;
      },
    },
  },
})
export default class Employee extends mixins(userRolesMixin, BaseView) {
  public $refs!: {
    form: Vue & { validate(): boolean };
    [key: string]: Vue | HTMLElement;
  };

  protected readonly rules = rules;
  protected readonly Routes = Routes;
  protected readonly createEmployeeProfile = createEmployeeProfile;

  protected creationFinalConfirmVisible = false;
  protected userPassword: string = "";

  protected user: EmployeeByIdQuery["user"] = null;

  @Watch("user", { immediate: true, deep: true })
  handlerUser(): void {
    this.stateUser = merge({}, this.stateUser, this.user);
  }

  private stateUser: Pick<
    Required<User>,
    | "family_name"
    | "first_name"
    | "city"
    | "sites"
    | "phones"
    | "emails"
    | "comment"
    | "birth_date"
    | "roles"
    | "second_name"
    | "sex"
    | "doctorProfile"
    | "pivot"
    | "employeeProfile"
  > & {
    avatar?: User["avatar"] | File;
    id?: User["id"];
    applications: Maybe<User["applications"]>;
    events: Maybe<User["events"]>;
  } = {
    applications: [],
    events: null,
    family_name: "",
    first_name: "",
    city: null,
    avatar: null,
    sites: [],
    phones: [],
    emails: [],
    comment: null,
    birth_date: "",
    roles: [],
    second_name: null,
    sex: null,
    doctorProfile: null,
    pivot: null,
    employeeProfile: null,
  };

  protected extensionNumber: number | null = null;

  /**
   * Обновить данные пользователя при смене ID маршрута
   * @private
   */
  @Watch("$route", { deep: true })
  private async refreshUser(): Promise<void> {
    await this.$apollo.queries.user.refetch();
  }

  /**
   * Флаг сообщает, что форма в режиме создания пользователя если нет ID в маршруте
   * @protected
   */
  protected get creationMode(): boolean {
    return !this.$route.params.id;
  }

  /**
   * Инпут профиля сотрудника для мутации
   * @private
   */
  private get employeeProfileInput(): UpdateEmployeeProfileInput | null {
    if (!this.stateUser.employeeProfile?.id) return null;
    const dataTransformer = new MutationTransformer();
    return {
      id: this.stateUser.employeeProfile.id,
      specialties: dataTransformer.transformArrayToSync(
        this.stateUser.employeeProfile?.specialties || []
      ),
      positions: dataTransformer.transformArrayToSync(
        this.stateUser.employeeProfile?.positions || []
      ),
    };
  }

  private get createUserInput() {
    const dataTransformer = new MutationTransformer();
    const user = this.stateUser;

    if (!user.family_name || !user.first_name) {
      return undefined;
    }

    const createUserInput: Omit<CreateUserInput, "login" | "password"> = {
      sex: user.sex,
      comment: user.comment,
      second_name: user.second_name,
      birth_date: user.birth_date,
      phones: this.phonesInput,
      emails: this.emailsInput,
      sites: this.sitesInput,
      family_name: user.family_name,
      first_name: user.first_name,
      employeeProfile: undefined,
      avatar: undefined,
      doctorProfile: undefined,
      city_id: undefined,
      roles: undefined,
    };

    if (this.extensionNumber)
      createUserInput.employeeProfile = {
        create: {
          extension_number: this.extensionNumber.toString(),
        },
      };

    if (user.avatar instanceof File || user.avatar === null)
      createUserInput.avatar = user.avatar;

    if (this.employeeProfileInput) {
      createUserInput.employeeProfile = { update: this.employeeProfileInput };
    }

    if (user.city) {
      createUserInput.city_id = omitDeep<typeof user.city, "id">(
        user.city,
        "id"
      );
    }
    if (user.roles)
      createUserInput.roles = dataTransformer.transformArrayToSync(user.roles);

    return createUserInput;
  }

  get emailsInput(): JsonInput[] | undefined {
    return this.stateUser.emails?.filter((email): email is JsonInput => {
      return isRequired(email);
    });
  }

  get phonesInput(): JsonInput[] | undefined {
    return this.stateUser.phones?.filter((phone): phone is JsonInput => {
      return isRequired(phone);
    });
  }

  get sitesInput(): JsonInput[] | undefined {
    return this.stateUser.sites?.filter((site): site is JsonInput => {
      return isRequired(site);
    });
  }

  private get updateUserInput(): UpdateUserInput | undefined {
    const dataTransformer = new MutationTransformer();
    const user = this.stateUser;

    if (!user.id) {
      return undefined;
    }

    const updateUserInput: UpdateUserInput = {
      id: user.id,
      emails: this.emailsInput,
      phones: this.phonesInput,
      sites: this.sitesInput,
    };

    if (user.avatar instanceof File || user.avatar === null)
      updateUserInput.avatar = user.avatar;

    if (user.birth_date !== this.user?.birth_date) {
      updateUserInput.birth_date = user.birth_date;
    }

    if (user.city) {
      updateUserInput.city_id = omitDeep<typeof user.city, "id">(
        user.city,
        "id"
      );
    }

    if (user.comment !== this.user?.comment) {
      updateUserInput.comment = user.comment;
    }

    if (this.employeeProfileInput) {
      updateUserInput.employeeProfile = { update: this.employeeProfileInput };
    }

    if (user.family_name !== this.user?.family_name) {
      updateUserInput.family_name = user.family_name;
    }

    if (user.first_name !== this.user?.first_name) {
      updateUserInput.first_name = user.first_name;
    }

    if (user.roles)
      updateUserInput.roles = dataTransformer.transformArrayToSync(user.roles);

    if (user.second_name !== this.user?.second_name) {
      updateUserInput.second_name = user.second_name;
    }

    if (user.sex !== this.user?.sex) {
      updateUserInput.sex = user.sex;
    }

    return updateUserInput;
  }

  protected error = null;
  protected isLoading = false;
  protected async updateUser(): Promise<void> {
    if (!this.updateUserInput) {
      return;
    }
    try {
      this.isLoading = true;
      await this.$apollo.mutate<
        UpdateUserMutation,
        UpdateUserMutationVariables
      >({
        mutation: UpdateUser,
        variables: {
          input: this.updateUserInput,
        },
      });
      await this.$router.push({ name: Routes.employees });
    } catch (e: any) {
      this.error = e;
    } finally {
      this.isLoading = false;
    }
  }

  protected async createUser(creds: {
    login: string;
    password: string;
    loginType: "email" | "phone";
  }): Promise<void> {
    if (
      // @ts-ignore из-за validation
      !this.$refs.form.validate() ||
      !this.createUserInput
    ) {
      return;
    }

    const { login, password, loginType } = creds;
    const userHasMainPhone = !!this.stateUser?.phones?.find((p) => p.is_main);
    const userHasMainEmail = !!this.stateUser?.emails?.find((p) => p.is_main);

    const variables: CreateUserMutationVariables = {
      input: { ...cloneDeep(this.createUserInput), password, login },
    };

    try {
      if (loginType === "email") {
        variables.input.emails?.push({
          is_main: !userHasMainEmail,
          value: login,
          title: "",
        });
      } else if (loginType === "phone") {
        variables.input.phones?.push({
          is_main: !userHasMainPhone,
          value: login,
          title: "",
        });
      }

      this.isLoading = true;
      const result = await this.$apollo.mutate<
        CreateUserMutation,
        CreateUserMutationVariables
      >({
        mutation: CreateUser,
        variables,
      });

      const user = result.data?.createUser;
      if (user)
        await this.$router.push({
          name: Routes.employeeID,
          params: { id: user.id },
        });
    } catch (e: any) {
      const ext = e.graphQLErrors[0]?.extensions;
      this.error = ext?.validation?.["input.login"]
        ? "Логин пользователя занят"
        : e;
    } finally {
      this.isLoading = false;
    }
  }
  isValidForm = false;
}
