






































import { Component, VModel, Vue } from "vue-property-decorator";
import { User } from "@/generated/graphql";

import Card from "@components/page/Card.vue";
import UserPositions from "@widgets/user/UserPositions.vue";
import UserSpeciality from "@widgets/user/UserSpecialty.vue";
import { numeric, required } from "@/core/validation";

@Component({
  components: {
    Card,
    UserPositions,
    UserSpeciality,
  },
})
export default class extends Vue {
  // todo: исправить тип, добавить параметры для prop
  @VModel({}) user!: User;

  get rules(): {
    numeric: (value: string) => true | string;
    required: (value: string) => boolean | string;
  } {
    return {
      required,
      numeric,
    };
  }
}
